@import '../../styles/variables';
@import '../../styles/mixins';

.staticDateContainer {
  display: flex;

  :global(.MuiPickersCalendarHeader-label) {
    font-family: $dmSans;
    font-weight: 400;
    color: $primary-darkest;
    font-size: 22px;
    text-transform: capitalize;
  }

  :global(.MuiPickerStaticWrapper-content) {
    background-color: transparent;
  }

  :global(.MuiPickersCalendarHeader-root) {
    margin-bottom: 40px;
  }

  :global(.MuiSvgIcon-root) {
    color: $primary-darkest;
  }

  :global(.MuiDayPicker-weekDayLabel) {
    font-family: $dmSans;
    font-weight: 400;
    font-size: 20px;
    color: $primary-darkest;
    width: 56px;
  }

  :global(.MuiPickersDay-root) {
    font-family: $dmSans;
    font-weight: 400;
    font-size: 18px;
    color: $primary-darkest;
    width: 56px;
    border-radius: 0;
    background-color: transparent;
  }

  :global(.MuiPickersDay-root.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.PrivatePickersYear-yearButton.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.MuiCalendarOrClockPicker-root) {
    > div {
      width: 100%;
      max-height: none;
    }
  }

  :global(.MuiCalendarPicker-root) {
    width: 100%;
    max-height: none;
  }
}

.textField {
  width: 100%;

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 9px !important;
  }

  :global(.MuiAutocomplete-root) {
    width: 100%;
  }

  :global(.MuiInputBase-root) {
    background-color: transparent;
  }

  :global(.MuiInputBase-root:hover) {
    border-radius: 0;
  }

  &.currencyInput {
    p {
      margin: 1px 0 0;
      line-height: 1.5;
      font-family: $jost;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 8px 14px 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 50px;
    }
  }

  label {
    font-family: $jost;
    color: $label;
    font-size: 14px;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 0;
    font-size: 14px;
    font-family: $jost;
    font-weight: 400;
    line-height: 1.4375;

    input {
      font-family: $jost;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $jost;
    margin: 8px 14px 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.switcherContainer {
  margin-top: 10px;

  :global(.MuiSwitch-switchBase) {
    color: $red-color;
  }

  :global(.Mui-checked) {
    color: rgb(0, 171, 85) !important;
  }

  :global(.Mui-checked + .MuiSwitch-track) {
    background-color: rgb(0, 171, 85) !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $red-color;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 14px;
    font-family: $jost;
    font-weight: 400;
  }
}

.checkboxContainer {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

  :global(.MuiSvgIcon-root) {
    width: 16px;
    height: 16px;
  }

  :global(.MuiFormControlLabel-label) {
    font-family: $jost;
    font-weight: 300;
    color: $primary-dark;
    font-size: 16px;
  }

  :global(.Mui-checked) {
    color: $primary-dark !important;
  }

  &:hover {
    background-color: $primary-light;
  }
}

.editorContainer {
  &.error {
    div:first-of-type {
      border-color: $red-color;
    }
  }

  .editorError {
    font-family: $jost;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red-color;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 8px 14px 0;
  }
}

.creatableButton {
  height: 34px;
}

.infoContent {
  width: 100%;
  margin-bottom: 8px;
}